import React from 'react'
import { withPrefix } from 'gatsby'

export default ({content, index}) => {
  return (
    <div className="section halfhalf-section mt-5" id={content.title} style={{background: 'white'}}>
      <div className="row">
        <div className="col-sm-5 left-half">
          <img src={ withPrefix(content.img) } className="img-fluid" alt={content.title}/>
        </div>
        <div className="col-sm-7 right-half" style={{backgroundColor: 'white'}}>
          <h4 className='text-center'><span className='badge badge-pill badge-primary-pink px-3'>{index + 1}</span></h4>
          <h2 className='text-center text-primary'>{content.title}</h2>
          <p dangerouslySetInnerHTML={{__html: content.description}}></p>
        </div>
      </div>
    </div>
  )
}
