import React from 'react'
import {Link, withPrefix, graphql} from 'gatsby'
import Layout from '../components/layouts/layout'
import HeadMeta from '../components/layouts/headmeta'
import ProgressBar from '../components/layouts/progressbar'
import HalfHalfContainer from '../components/halfhalfcontainer'
import { changeProgressBarOnScroll } from '../components/utils/index'

export default class HowItWorksPage extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', function() {
      changeProgressBarOnScroll()
    });
    window.analytics.page('Static', 'How It Works')
  }

  render() {
    const { markdownRemark: post } = this.props.data
    const {
      title, description, content, metadata, faqs
    } = post.frontmatter

    return (
      <Layout>
        <div id="how-it-works">
          <HeadMeta metadata={metadata}/>
          <ProgressBar />
          <section className="hero bg-gradient-blue-green text-white" style={{height: '50vh'}}>
            <div className="container">
              <div className="row align-items-center">
                <div className="text-center col-md-7">
                  <h1 className="">{title}</h1>
                  <p className="text-black" dangerouslySetInnerHTML={{__html: description}}></p>
                </div>
                <div className="col-md-5 text-center d-none d-md-flex">
                  <img className="img-fluid pr-5" src="/img/product/kit-content-senget.png" alt="Product kit"/>
                </div>
              </div>
            </div>
          </section>
          { content.map((content, index) => (
            <HalfHalfContainer content={content} key={index} index={index}/>
          ))}
          <section className="text-center pb-0">
            <h1>What's In The Kit</h1>
            <img className="img-fluid" src={withPrefix('img/product/kit-content.jpg')} style={{width: '100%'}} alt="what's in the kit"/>
          </section>
          <section className="container pb-0">
            <div className="row">
              <div className="col-md-6 order-2 order-md-1">
                <h2 className="text-center mb-5 mt-5 mt-md-2">How Is Your DNA Proccessed?</h2>
                <ul>
                  <p><i className="fa fa-check text-primary"></i> Saliva samples are processed in ISO17025-accredited laboratory in a renowned research institute in Malaysia.</p>
                  <p><i className="fa fa-check text-primary"></i> Our laboratory personnels are scientists that are trained in running the workflow with high stringency.</p>
                  <p><i className="fa fa-check text-primary"></i> Your personalised reports are based on curated database of well-established, peer-reviewed publications.</p>
                  <p><i className="fa fa-check text-primary"></i> Your reports are also proof read by PhD scientists, and there will be citations in the report for all contents.</p>
                </ul>
              </div>
              <div className="col-md-6 order-1 order-md-2">
                <img src={withPrefix('/img/product/lab.jpg')} className="img-fluid" alt="lab"/>
              </div>
            </div>
          </section>
          <section id="faq">
            <h2 className="text-center mx-4">Got questions?</h2><br/>
            <p className='text-center'>
              Find out more  at our Frequently Asked Questions (F.A.Q)
              <br/>
              or Customer Care & Support Page
            </p>
            <div className='d-flex justify-content-center mt-4 flex-wrap'>
              <Link
                to="/faq"
                className="btn btn-pink m-3"
                target="_blank"
              >
                Frequently Asked <br/> Questions (F.A.Q)
              </Link>
              <Link 
                to="/support"
                className="btn btn-primary m-3"
                target="_blank"
              >
                Customer Care<br/> & Support
              </Link>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export const howItWorksPageQuery = graphql`
  query HowItWorksByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        title
        description
        metadata {
          title
          description
          url
          image
        }
        content {
          img
          title
          description
        }
        faqs {
          question
          answer
        }
      }
    }
  }
`
